// eslint-disable-next-line import/prefer-default-export
export const casualDiningHero = {
  title: "Casual dining POS system",
  subtext:
    "Streamline operations with an end-to-end POS system that helps you grow revenue and connect with your guests.",
  PrimaryCta: {
    ctaTitle: "Learn more",
    ctaTarget: "/casual-dining-pos/demo",
  },
};
export const casualDiningSubHero = {
  title: "Powerful tools that are easy to use",
  subtext:
    "Delight your loyal guests, attract new ones, and provide a contactless environment to ensure everyone is safe. SpotOn has the right tools to help you get it done—tools that work side-by-side to help you save time, grow revenue, and create a first-class guest experience.",
  PrimaryCta: {
    ctaTitle: "Learn more",
    ctaTarget: "/casual-dining-pos/demo",
  },
};

export const casualDiningWhiteGlove = {
  mainTitle: "We are here for you",
  title: "Let someone serve you for a change",
  content:
    "You have a lot on your plate. Let SpotOn lighten your load. Our team will work closely with you to customize the software and point-of-sale technology you need, provide in-person installation and training, and continue to be there anytime you need us with 24/7 support.",
  ctaInfoPrimary: {
    ctaTitle: "Learn more",
    ctaTarget: "/casual-dining-pos/demo",
  },
};

export const casualDiningLargeFeatureA = {
  featureBlocks: [
    {
      blockTitle: "The custom-built solution you deserve",
      blockSubTitle:
        "Maximize sales and simplify operations with an end-to-end solution that is far more than your ordinary POS. We’ll help you unlock your casual dining restaurant’s potential with contactless ordering and payments, handhelds, online ordering, elite reporting, and more.",
      blockImg: "olo-modified.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/casual-dining-pos/demo",
      },
    },
  ],
};

export const casualDiningTrusted = [
  {
    title: "67%",
    subtext: "Increase in repeat visits with online ordering",
  },
  {
    title: "$7,500+",
    subtext: "Monthly savings compared to third-party delivery apps",
  },
  {
    title: "$800+",
    subtext:
      "Monthly savings over reservation platforms that charge per-cover fees",
  },
];

export const autoPartsMultiSection = {
  sectionTitle: "Technology built for casual dining",
  featureBlocks: [
    {
      blockTitle: "SpotOn Restaurant",
      blockSubTitle:
        "Work faster and smarter with a point-of-sale that combines all the features you need to create an amazing guest experience—and none of the hassle.",
      blockList: [
        "Custom menu build & table layouts",
        "Lightning-fast ordering & payments",
        "Handheld POS with contactless payments",
        "Real-time updates & reporting",
        "Remote back office",
        "Multi-location management",
      ],
      blockImg: "casual-dining-hardware.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/casual-dining-pos/demo",
      },
    },
    {
      blockTitle: "SpotOn Serve",
      blockSubTitle:
        "Increase throughput and enhance your guest experience by offering order and pay at the table. No more time wasted running back and forth to a POS station. Now your servers have everything they need in the palm of their hand.",
      blockImg: "rest-a.png",
      ImgAltTag: "casual dining pos",
      blockList: [
        "Send orders to the kitchen in real-time",
        "Capture guest data with SpotOn Loyalty",
        "Pay-at-table with EMV, Apple Pay, and Android Pay card reader",
        "Built-in touchscreen display and thermal printer",
        "Patio, parklet, curbside, table-side - serve your guests wherever they are with Wi-Fi and 4G LTE connectivity",
      ],
      forceReverse: false,
      ctaInfo: {
        ctaTitle: "Learn more",
        ctaTarget: "/casual-dining-pos/demo",
      },
    },
    {
      blockTitle: "SpotOn Order",
      blockSubTitle:
        "Delivery and take-out have become an essential part of your success. SpotOn Order makes ordering and paying easy for your guests while eliminating costly commissions from third-party apps. Let your guests order take-out, delivery, and e-gift cards directly from your website with full POS integration to streamline operations. SpotOn QR also allows customers to order and pay directly from their phone for in-person guests, helping you turn tables quicker and relieve stress on servers.",
      blockImg: "casual-online-ordering.png",
      ImgAltTag: "casual dining pos",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/casual-dining-pos/demo",
      },
    },
    {
      blockTitle: "SpotOn Delivery, Powered by DoorDash",
      blockSubTitle:
        "SpotOn Delivery gives you access to the DoorDash delivery network for a low flat fee instead of the costly commissions associated with third-party apps. You also keep your own guest data, so to your guests instead of the competition.",
      blockList: [
        "Guests order directly from your website",
        "Flat rate fee* with the ability to pass costs on to guests",
        "DoorDash handles delivery logistics",
        "You keep your guest data for remarketing",
      ],
      appendix: `*$7.95 per delivery; $9.95 per delivery in California`,
      blockImg: "delivery-c.png",
      ImgAltTag: "casual dining pos",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/casual-dining-pos/demo",
      },
    },
    {
      blockTitle: "SpotOn Reserve",
      blockSubTitle:
        "Take the hassle out of managing capacity limitations, contact tracing requirements, and curbside pickup. Our front-of-house solution will help keep your tables full and save you thousands compared to reservation platforms that charge per-cover fees. ",
      blockImg: "reserve.png",
      blockList: [
        "Reservations & waitlisting",
        "Server & table management",
        "Contact tracing",
        "Two-way table-ready messaging",
        "Guest analytics",
      ],
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/casual-dining-pos/demo",
      },
    },
  ],
};

export const casualDiningCTA = {
  title: "Book your consultation.",
  ctaImg: "cta-people.png",
  ctaInfo: {
    ctaTitle: "Learn more",
    ctaTarget: "/casual-dining-pos/demo",
  },
};

export const casualDiningVideo = {
  title: "See SpotOn solutions in action",
  videoBtn: "Watch video",
  posterImg: "terminal-poster.png",
  videoUrlId: "CTN467eYNF8",
};

export const casualDiningTestimonials = {
  title: "What other business owners are saying",
  reviews: [
    {
      quote:
        "From the initial consultation, to ordering, to implementation and integration, the entire process of on-boarding the SpotOn POS System has been seamless and has exceeded our expectations.",
      reviewDate: "Mar 9, 2021",
      reviewer: "Pete M.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn continues to be an invaluable technology partner that listens well and proactively presents innovative solutions to drive our business forward.",
      reviewDate: "Feb 9, 2021",
      reviewer: "Ben P.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn has given me the data access I need to manage my business from a macro as well as micro perspective. The software is easy to access and utilize.",
      reviewDate: "Dec 5, 2020",
      reviewer: "Pamela H.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "Every issue and question has been answered or addressed within hours, if not minutes.I can't say enough good about this company and software.",
      reviewDate: "Feb 24, 2021",
      reviewer: "Brandy R.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "From back office, digital marketing, built our website to take orders off of. The programming is great to allow you to do more tasks with better trained staff.",
      reviewDate: "Dec 1, 2020",
      reviewer: "Marcus O.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
  ],
};

export const casualDiningArticles = {
  title: "Expert advice on growing your business",
  bgText: "Articles.",
};

export const casualDiningFaqs = {
  title: "Frequently Asked Questions",
  ctaInfo: {
    ctaTitle: "View more",
    ctaTarget: "/casual-dining-pos/demo",
  },
  list: [
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
  ],
};

export const casualDiningCar = {
  title: "Run a different type of restaurant",
  subtext: "Casual dining restaurants are just one of our specialties.",
  punctuation: "?",
  slides: [
    {
      bg: "quick-dining.png",
      cardTitle: "Quick service restaurant",
      cardContent: "Work faster and smarter",
      icon: "qsr.png",
      linkTo: "/quick-service-pos",
    },
    {
      bg: "fine-dining.png",
      cardTitle: "Fine Dining",
      cardContent: "Tailor-made restaurant solutions",
      icon: "fine-dining.png",
      linkTo: "/fine-dining-pos",
    },
    {
      bg: "food-truck.png",
      cardTitle: "Food Trucks",
      cardContent: "Tech that can keep up with your food truck",
      icon: "food-trucks.png",
      linkTo: "/food-truck-pos",
    },
  ],
};
