import React from "react";
import loadable from "@loadable/component";
import Layout from "../../components/Layout/layout";
import SEO from "../../components/seo";
import BHero from "../../components/Hero/b-hero";
import {
  casualDiningHero,
  casualDiningSubHero,
  casualDiningWhiteGlove,
  casualDiningLargeFeatureA,
  casualDiningTrusted,
  autoPartsMultiSection,
  casualDiningCTA,
  casualDiningVideo,
  casualDiningTestimonials,
  casualDiningCar,
} from "../../data/casual-dining-data";
import { blueBarnSlides, heroSuccess } from "../../data/success-stories-data";

import heroBg from "../../images/hero-images/casual-dining-hero.png";

const NoVisualsCustom = loadable(() =>
  import("../../components/NoVisuals/NoVisualsCustom")
);
const WhiteGlove = loadable(() =>
  import("../../components/WhiteGlove/WhiteGlove")
);
const LargeFeatures = loadable(() =>
  import("../../components/LargeFeatures/CasualDiningLF")
);
const TrustedNumbers = loadable(() =>
  import("../../components/TrustedNumbers/TrustedNumbers")
);
const LargeCta = loadable(() => import("../../components/CTAs/LargeCta"));
const SuccessStories = loadable(() =>
  import("../../components/SuccessStories/SuccessStories")
);
const VideoSection = loadable(() =>
  import("../../components/VideoSection/VideoSection")
);
const TestmonialReviews = loadable(() =>
  import("../../components/TestimonialReviews/TestmonialReviews")
);
const BusinessTypes = loadable(() =>
  import("../../components/BusinessTypes/BusinessTypes")
);
// const CapterraBanner = loadable(() =>
//   import("../../components-v2/CapterraBanner")
// );
const TopRestaurantsLogos = loadable(() =>
  import("../../components-v2/TopRestaurantsLogos")
);

const partnersTitle = "Used by these popular restaurants and bars:";
const whiteGloveImport = "restaurant-single-white-glove.png";

const CasualDining = () => {
  return (
    <Layout>
      <SEO
        title="Casual Dining POS System | Point of Sale System for Casual Dining | SpotOn"
        description="SpotOn POS has many features for casual dining, including handheld devices to eliminate wait times, multi-location management, real-time reporting, and more."
        image={`https://spoton.com/${heroBg}`}
        cannonical="https://www.spoton.com/casual-dining-pos"
        productSchemaSR
      />
      <BHero sectionData={casualDiningHero} heroBg="casual-dining-hero.png" />
      <NoVisualsCustom sectionData={casualDiningSubHero} simple />
      <WhiteGlove
        sectionData={casualDiningWhiteGlove}
        whiteGloveBg={whiteGloveImport}
      />
      <LargeFeatures
        sectionData={casualDiningLargeFeatureA}
        style={{ marginTop: "128px" }}
      />
      <TrustedNumbers
        title="By the numbers"
        numbersArray={casualDiningTrusted}
      />
      <LargeFeatures
        sectionData={autoPartsMultiSection}
        style={{ marginTop: "128px !important" }}
      />
      {/* <CapterraBanner
        className="mt-16 lg:mt-20 mb-20 lg:mb-40"
        imageName="capterra-restaurant-pos.png"
        title={`Rated <span class="text-primary">the #1</span> Restaurant POS system by real users<span class="text-primary">.</span>`}
        withCta
        ctaLabel="See the results"
        ctaTarget="https://datainsights.capterra.com/p/restaurant-pos/226928/spoton/references?g=na&c=&c=&c=&sc=6321593"
      /> */}
      <LargeCta sectionData={casualDiningCTA} />
      <SuccessStories sectionData={heroSuccess} slides={blueBarnSlides} />
      <VideoSection sectionData={casualDiningVideo} />
      <TopRestaurantsLogos sectionTitle={partnersTitle} />
      <TestmonialReviews sectionData={casualDiningTestimonials} />
      <BusinessTypes sectionData={casualDiningCar} />
    </Layout>
  );
};

export default CasualDining;
